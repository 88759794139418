// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-layouts-play-temp-js": () => import("./../../src/layouts/play-temp.js" /* webpackChunkName: "component---src-layouts-play-temp-js" */),
  "component---src-layouts-project-temp-js": () => import("./../../src/layouts/project-temp.js" /* webpackChunkName: "component---src-layouts-project-temp-js" */),
  "component---src-pages-about-js": () => import("./../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-play-js": () => import("./../../src/pages/play.js" /* webpackChunkName: "component---src-pages-play-js" */),
  "component---src-pages-👻-js": () => import("./../../src/pages/👻.js" /* webpackChunkName: "component---src-pages-👻-js" */)
}

